import React, { useEffect, useState } from 'react'
import MobileNav from './mobileNav'
import Navigation from './navigation'
import { isMobile } from 'react-device-detect'
import '../stylesheets/navbar.css'

const NavBar = () => {
    const [hide, setHide] = useState(false)
    const [lastScrollY, setLastScrollY] = useState(0)
    const defaultNav = <Navigation />
    const mobNav = <MobileNav />

    const hideNavbar = () => {
        if (window.scrollY > (lastScrollY)) {
            setHide(true)
        } else {
            setHide(false)
        }
        setLastScrollY(window.scrollY)
    }

    useEffect(() => {
        window.addEventListener('scroll', hideNavbar)
        return () => {
            window.removeEventListener('scroll', hideNavbar)
        }
    }, [lastScrollY])

    return (
        <div className={`navbar ${hide && 'hide'}`}>
            {isMobile ? mobNav : defaultNav}
        </div>
    )
}

export default NavBar