import * as React from 'react'
import { Link } from 'gatsby'
import Logo from '../images/andrea-logo.png'
import '../stylesheets/navbar.css'

const Navigation = () => {

    return (
        <div className='nav'>
            <Link to='/' id='logo' className='navbarBrand'>
                <img src={Logo} alt='logo for andrea garcia' />
            </Link>
            <ul className='menu' id='menu-toggle'>
                <li className='menuItem' ><Link to='/' className='link'>Home</Link></li>
                <li className='menuItem'><Link to='/about' className='link'>About</Link></li>
                <li className='menuItem'><Link to='/causes' className='link'>Causes to Follow</Link></li>
            </ul>
        </div>
    )
}

export default Navigation