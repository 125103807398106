import React, { useState } from 'react'
import { Link } from 'gatsby'
import { isMobile } from 'react-device-detect'
import { GrMenu, GrClose } from 'react-icons/gr'
import Logo from '../images/andrea-logo.png'
import '../stylesheets/navbar.css'

const MobileNav = () => {

    const [menu, setMenu] = useState()

    const burgerIcon = <GrMenu
        className='burger'
        onClick={() => setMenu(!menu)}
    />

    const closeIcon = <GrClose
        className='burger'
        onClick={() => setMenu(!menu)}
    />

    const openMenu = () => setMenu(true)

    const bckgrndClr = {
        backgroundColor: 'hsl(241, 61%, 87%, 0.85)',
        height: '50vh',
        width: '75vw'
    }

    const fullMenu = <ul className='menu' id='menu-toggle' style={isMobile ? bckgrndClr : null}>
        <li className='menuItem'><Link to='/' className='link'>Home</Link></li>
        <li className='menuItem'><Link to='/about' className='link'>About</Link></li>
        <li className='menuItem'><Link to='/causes' className='link' >Causes to Follow</Link></li>
    </ul>

    const mobileView = <div className='menuRight'>
        {menu ? closeIcon : burgerIcon}
        {menu && fullMenu}
    </div>

    const desktopView = <div className='menuRight'>
        {openMenu}
        {menu ? menu && fullMenu : openMenu}
    </div>

    return (
        <div className='navMobile'>
            <Link to='/' id='logo' className='navbarBrand'>
                <img src={Logo} alt='logo for marjoree fargas' />
            </Link>
            {isMobile ? mobileView : desktopView}
        </div>
    )
}
export default MobileNav